<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <trains-form ref="trainsForm"/>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import VueAddButton from '@/components/ui/vueAddButton.vue'
import BusesRoute from '@/components/administration/buses/busesRoute.vue'
import TrainsForm from '@/components/administration/trains/trainsForm.vue'
import TrainsRoutes from '@/components/administration/trains/trainsRoutes.vue'
export default {
    components: {
        VueBreadcrumbs,
        VueAddButton,
        BusesRoute,
        TrainsForm,
        TrainsRoutes
    },
    data: () => ({
        breadCrumbs: [
            {
                text: 'Заведение блоков',
                to: '/administration/block'
            },
            {
                text: 'Поезда',
                to: '/administration/block/trains',
            },
            {
                text: 'Вагоны',
                to: '/administration/block/trains/1',
            }
        ]
    })
}
</script>

<style lang="scss" scoped>
.tab_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
</style>