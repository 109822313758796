<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items.results"
            :loading="loading"
            :options.sync="options"
            disable-sort
            :server-items-length="items.count"
        >
            <template v-slot:[`item.railway_type`]="{ item }">
                {{
                    item.railway_type=='reserved' ? 'Плацкарт' : 
                    item.railway_type=='coupe' ? 'Купе' : 
                    item.railway_type=='sedentary' ? 'Сидячий' : 
                    item.railway_type=='luxury' ? 'Люкс' : 'Гранд'
                }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <vue-table-actions :item='item' @onEdit='onEdit(item)' @onDelete='onDelete(item)'/>
            </template>
        </v-data-table>
        <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' @onSubmit='onSubmit'>
            <v-form
                ref="form"
                class="railways_form"
                lazy-validation
            >
                <input-content
                    :items="inputItems"
                    :model="model"
                />
                <train-model :type='model.railway_type' class="mt-4 wagon_model"/>
            </v-form>
        </vue-dialog>
        <vue-delete-dialog :model='deleteDialog' @onClick='deleteHandler' @closeDialog='closeDelete' :text='"Вы уверены что хотите удалить вагон?"'/>
    </div>
</template>

<script>
import VueAddButton from '@/components/ui/vueAddButton.vue'
import VueTableActions from '@/components/ui/vueTableActions.vue'
import VueDialog from '@/components/ui/vueDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import vueDeleteDialog from '@/components/ui/vueDeleteDialog.vue'
import { trainService } from '@/services/trains.service.js'
import TrainModel from '@/components/trains/trainModel.vue'
import pagination from "@/utils/pagination"
export default {
    mixins: [pagination],
    components: {
        VueAddButton,
        VueTableActions,
        VueDialog,
        vueDeleteDialog,
        InputContent,
        TrainModel
    },
    data: () => ({
        model: {},
        loading: false,
        editId: null,
        deleteId: null,
        deleteDialog: false,
        dialogOptions: {
            width: '1000',
            title: 'Вагон',
            button: 'Сохранить'
        },
        inputItems: [
            {
                type: 'text',
                label: 'Номер поезда',
                rules: 'required',
                model: 'train_number',
                cols: 4
            },
            {
                type: 'text',
                label: 'Номер вагона',
                rules: 'required',
                model: 'railway_number',
                cols: 4
            },
            {
                type: 'select',
                label: 'Тип вагона',
                rules: 'required',
                model: 'railway_type',
                hardcoded: true,
                items: [
                    { name: 'Купе', id: 'coupe' },
                    { name: 'Плацкарт', id: 'reserved' },
                    { name: 'Сидячий', id: 'sedentary' },
                    { name: 'Люкс', id: 'luxury' },
                    { name: 'Гранд', id: 'grandee' },
                ],
                cols: 4
            },
        ],
        dialog: false,
        items: [],
        headers: [
            { text: 'Поезд', value: 'train_number' },
            { text: 'Номер вагона', value: 'railway_number' },
            { text: 'Тип', value: 'railway_type' },
            { text: '', value: 'actions' },
        ],
    }),
    async created() {
        this.getList()
    },
    methods: {
        async getList() {
            this.loading = true
            let params = {
                page: this.options.page, 
                size: this.options.itemsPerPage
            }
            if (this.$route.params.id) {
                params = {
                    route: this.$route.params.id
                }
            }
            const res = await trainService.getRailsways(params)
            this.items = res
            this.loading = false
        },
        onAdd() {
            this.model = {}
            this.dialog = true
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
        },
        async onEdit(item) {
            this.model = this.$copy(item)
            this.dialog = true
        },
        closeDelete() {
            this.deleteDialog = false
            this.deleteId = null
        },
        onDelete(item) {
            this.deleteId = item.id
            this.deleteDialog = true
        },
        closeDialog() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
            this.model = {}
            this.dialog = false
            this.editId = null
        },
        async deleteHandler() {
            try {
                this.$loading(true)
                await trainService.deleteRailway(this.deleteId)
                await this.getList()
            } catch (e) {
                console.error(e);
            } finally {
                this.closeDelete()
                this.$loading(false)
            }
        },
        async onSubmit() {
            if (this.$refs.form) {
                if (!this.$refs.form.validate()) return
            }
            try {
                this.$loading(true)
                const data = {
                    ...this.model,
                    is_layout: true,
                }
                if (data.id) {
                    await trainService.patchRailsways(data)
                }  else {
                    await trainService.postRailsways(data)
                }
                await this.getList()
            } catch(e) {
                console.error(e);
            } finally {
                this.dialog = false
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.wagon_model {
    margin-left: -30px;
}
</style>