<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="tourRoutes.results"
            :loading='loading'
            disable-sort
            :options.sync="options"
            @click:row="goPage"
            :server-items-length="tourRoutes.count"
        >
            <template v-slot:[`item.is_back`]="{ item }">
                <div style="pointer-events: none;">
                    <v-checkbox
                        v-model="item.is_back"
                        hide-details
                        readonly
                        dense
                        class="mb-2"
                    >
                    </v-checkbox>
                </div>
            </template>
            <template v-slot:[`item.main_date`]="{ item }">
                {{$moment(item.main_date*1000).format("DD MMM YYYY")}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="table_actions">
                    <v-btn @click.stop="onCopy(item)" icon>
                        <v-icon size="20">mdi-content-copy</v-icon>
                    </v-btn>
                    <vue-table-actions :item='item' @onEdit='onEdit(item)' @onDelete='onDelete(item)'/>
                </div>
            </template>
        </v-data-table>
        <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' :hideButton='true'>
            <v-tabs-items v-model="tab" class="pa-1">
                <v-tab-item>
                    <v-form
                        ref="first_form"
                        lazy-validation
                    >
                        <input-content
                            :items="inputItems"
                            :model="model"
                            :dialog='dialog'
                        />
                    </v-form>
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4"
                        @click="nextStep"
                    >
                        Далее
                    </v-btn>
                </v-tab-item>
                <v-tab-item>
                    <v-form
                        ref="second_form"
                        lazy-validation
                    >
                        <input-content
                            :items="wagonItems"
                            :model="wagonModel"
                            :dialog='dialog'
                        />
                    </v-form>
                    <div v-if="wagonModel.railway">
                        <train-model :type='wagonModel.railway.type || wagonModel.railway.railway_type' class="mt-3 wagon_model"/>
                    </div>
                    <div v-if="isEditingWagon">
                        <v-btn
                            color="error"
                            height="44"
                            width="160"
                            class="mt-4 mr-4"
                            @click="removeWagon"
                        >
                            удалить
                        </v-btn>
                        <v-btn
                            color="primary"
                            height="44"
                            width="160"
                            class="mt-4"
                            @click="saveWagon"
                        >
                            Сохранить
                        </v-btn>
                    </div>
                    <div class="wagons_list">
                        <div
                            v-for="wagon, idx in wagons"
                            :key="idx"
                            @click="setWagonModel(idx)"
                        >
                            <img src="@/assets/images/train/railway.svg" alt="">
                            №{{wagon.railway.hiddenName || wagon.railway.railway_number}}
                        </div>
                        <div v-if="!isEditingWagon">
                            <v-btn class="mt-2" outlined color="primary" small @click="addWagon">
                                Добавить
                            </v-btn>
                        </div>
                    </div>
                    <v-row class="pa-3">
                        <v-btn
                            color="primary"
                            height="44"
                            width="160"
                            class="mt-4"
                            @click="setTab(0)"
                        >
                            Назад
                        </v-btn>
                        <v-spacer/>
                        <v-btn
                            v-if="wagons.length"
                            color="primary"
                            height="44"
                            width="160"
                            class="mt-4"
                            @click="lastStep"
                        >
                            Далее
                        </v-btn>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <multiple-create ref="createMultiple" :dialog='dialog' :inputItems='stationInputs'/>
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4 mr-2"
                        @click="tab = 1"
                    >
                        Назад
                    </v-btn>
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4"
                        @click="onSubmit"
                    >
                        Сохранить
                    </v-btn>
                </v-tab-item>
            </v-tabs-items>
        </vue-dialog>
        <vue-delete-dialog :model='deleteDialog' @onClick='deleteHandler' @closeDialog='closeDelete' :text='"Вы уверены что хотите удалить маршрут?"'/>
    </div>
</template>

<script>
import VueDialog from '@/components/ui/vueDialog.vue'
import VueTableActions from '@/components/ui/vueTableActions.vue'
import vueDeleteDialog from '@/components/ui/vueDeleteDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import multipleCreate from '@/components/ui/multipleCreate.vue'
import TrainModel from '@/components/trains/trainModel.vue'
import { trainService } from '@/services/trains.service.js'
import VueAddButton from '@/components/ui/vueAddButton.vue'
import pagination from "@/utils/pagination"

export default {
    mixins: [pagination],
    components: {
        VueDialog,
        InputContent,
        vueDeleteDialog,
        VueTableActions,
        multipleCreate,
        TrainModel,
        VueAddButton
    },
    data: () => ({
        isEditingWagon: false,
        wagons: [],
        model: {},
        wagonModel: {},
        multipleArr: [],
        tourRoutes: {},
        loading: false,
        deleteDialog: false,
        tab: 0,
        editId: null,
        items: [],
        deleteId: null,
        dialog: false,
        response: {},
        stationInputs: [
            {
                type: 'select',
                label: 'Станция',
                items: [],
                model: 'station',
                getItems: 'getStations',
                rules: 'required',
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'День прибытия',
                model: 'arrival_day',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'time',
                label: 'Прибытие',
                model: 'arrival_time',
                rules: 'required',
                cols: 6
            },
        ],
        wagonItems: [
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость взрослого OW',
                model: 'adult_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость взрослого RT',
                model: 'adult_rt_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость детского OW',
                model: 'child_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость детского RT',
                model: 'child_rt_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость пенсионера OW',
                model: 'pensioner_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость пенсионера RT',
                model: 'pensioner_rt_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Возраст ребёнка',
                model: 'child_age',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Возраст пенсионера',
                model: 'pensioner_age',
                rules: 'required',
                cols: 6
            },
            // {
            //     type: 'text',
            //     inputType: 'number',
            //     label: 'Скидка',
            //     model: 'discount',
            //     rules: 'required',
            //     cols: 6
            // },
            {
                type: 'select',
                label: 'Вагон',
                items: [],
                model: 'railway',
                getItems: 'getRailwaysWithTrain',
                object: true,
                rules: 'required',
                cols: 6
            },
        ],
        inputItems: [
            {
                type: 'date',
                label: 'Даты отправления',
                model: 'dates',
                multiple: true,
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'time',
                label: 'Время отправления',
                model: 'travel_time',
                rules: 'required',
                cols: 6
            },
            {
                type: 'select',
                label: 'Откуда',
                items: [],
                model: 'main_station',
                getItems: 'getStations',
                rules: 'required',
                cols: 6
            },
            // {
            //     type: 'text',
            //     inputType: 'number',
            //     label: 'День прибытия',
            //     model: 'days',
            //     rules: 'required',
            //     cols: 3
            // },
            // {
            //     type: 'text',
            //     inputType: 'time',
            //     label: 'Прибытие',
            //     model: 'next_time',
            //     rules: 'required',
            //     cols: 3
            // },
            {
                type: 'checkbox',
                label: 'Обратно?',
                model: 'is_back',
                rules: 'required',
                cols: 6
            },
        ],
        headers: [
            { text: '№', value: 'id' },
            { text: 'Станция', value: 'main_station.name' },
            { text: 'Обратно', value: 'is_back' },
            { text: 'Дата отправления', value: 'main_date' },
            { text: 'Время отправления', value: 'travel_time' },
            { text: '', value: 'actions' },
        ],
        dialogOptions: {
            width: '771',
            title: 'Маршрут',
        },
    }),
    watch: {
        'model.is_back': function(val) {
            if (val) {
                this.inputItems[2].label = 'Куда'
                this.inputItems[1].label = 'Время прибытия'
                this.inputItems[0].label = 'Даты прибытия'
                this.stationInputs[1].label = 'День прибытия'
                this.stationInputs[2].label = 'Прибытие'
            } else {
                this.inputItems[2].label = 'Откуда'
                this.inputItems[1].label = 'Время отправления'
                this.inputItems[0].label = 'Даты отправления'
                this.stationInputs[1].label = 'День отправления'
                this.stationInputs[2].label = 'Отправление'
            }
        }
    },
    watch: {
        dialog(val) {
            if (val) {
                if (this.$refs.first_form) {
                    this.$refs.first_form.resetValidation()
                }
            }
        }
    },
    methods: {
        async onCopy(item) {
            this.setTab(0)
            this.model = {}
            this.model = this.$copy(item)
            this.inputItems.forEach(el => {
                if (el.model == 'dates') {
                    el.multiple = true
                }
            })
            const res = await trainService.getSingleRailwaysRoutes(item.id)
            this.response = this.$copy(res)
            this.wagons = res.route_railways
            this.multipleArr = res.route_stations.map(el => {
                let h = Math.floor(el.travel_time % (3600*24) / 3600)
                let m = Math.floor(el.travel_time % 3600 / 60)
                return {
                    id: el.id,
                    arrival_day: Math.floor(el.travel_time / (3600*24)),
                    arrival_time: `${h<9?'0'+h:h}:${m<9?'0'+m:m}`,
                    station: el.station.id ?? el.station
                }
            })
            this.model.id = null
            this.dialog = true
        },
        getTravelTime(item) {
            const [hours, mins] = this.$moment(item.from_date*1000).format('HH:mm').split(':')
            let seconds = hours*3600+mins*60+item.travel_time
            let h = Math.floor(seconds % (3600*24) / 3600);
            let m = Math.floor(seconds % 3600 / 60);
            return `${Math.floor(seconds / (3600*24)) > 0 ? Math.floor(seconds / (3600*24))+' дней' : ''} ${h<9?'0'+h:h}:${m<9?'0'+m:m}`
        },
        goPage({id}) {
            this.$router.push(`/administration/block/trains/${id}/`)
        },
        removeWagon() {
            this.$refs.second_form.resetValidation()
            this.wagons = this.wagons.filter(el => el != this.wagonModel)
            this.wagonModel = {}
            this.isEditingWagon = false
        },
        saveWagon() {
            this.$refs.second_form.resetValidation()
            this.isEditingWagon = false
            this.wagonModel = {}
        },
        async getList() {
            this.loading = true
            let params = {
                page: this.options.page, 
                size: this.options.itemsPerPage
            }
            this.tourRoutes = await trainService.getRailswaysRoute(params)
            this.loading = false
        },
        setTab(tab) {
            this.tab = tab
        },
        setWagonModel(idx) {
            this.isEditingWagon = true
            this.wagonModel = this.wagons[idx]
        },
        addWagon() {
            if (!this.$refs.second_form.validate()) return
            this.wagons.push(this.wagonModel)
            this.$refs.second_form.resetValidation()
            this.wagonModel = {}
        },
        nextStep() {
            if (!this.$refs.first_form.validate()) return
            this.setTab(1)
        },
        lastStep() {
            if (!this.wagons.length) {
                if (!this.$refs.second_form.validate()) return
            }
            if (this.model.is_back) {
                this.stationInputs[1].label = 'День отправления'
                this.stationInputs[2].label = 'Отправление'
            } else {
                this.stationInputs[1].label = 'День прибытия'
                this.stationInputs[2].label = 'Прибытие'
            }
            this.setTab(2)
            setTimeout(() => {
                this.$refs.createMultiple.setModel(this.multipleArr)
            }, 0)
        },
        closeDialog() {
            if (this.$refs.first_form) {
                this.$refs.first_form.reset()
            }
            this.dialog = false
            this.response = {}
            this.multipleArr = []
            this.wagons = []
            this.wagonModel = {}
            this.model = {}
        },
        onAdd() {
            this.inputItems.forEach(el => {
                if (el.model == 'dates') {
                    el.multiple = true
                }
            })
            this.setTab(0)
            this.model = {}
            this.dialog = true
        },
        async setEditItem(item) {
            this.model = this.$copy(item)
            this.inputItems.forEach(el => {
                if (el.model == 'dates') {
                    el.multiple = false
                }
            })
            this.model.dates = this.$moment(item.main_date*1000).format('YYYY-MM-DD')
            const res = await trainService.getSingleRailwaysRoutes(item.id)
            this.response = this.$copy(res)
            this.wagons = res.route_railways
            this.multipleArr = res.route_stations.map(el => {
                let h = Math.floor(el.travel_time % (3600*24) / 3600)
                let m = Math.floor(el.travel_time % 3600 / 60)
                return {
                    id: el.id,
                    arrival_day: Math.floor(el.travel_time / (3600*24)),
                    arrival_time: `${h<9?'0'+h:h}:${m<9?'0'+m:m}`,
                    station: el.station.id ?? el.station
                }
            })
        },
        async onEdit(item) {
            this.setTab(0)
            this.model = {}
            await this.setEditItem(item)
            this.dialog = true
        },
        closeDelete() {
            this.deleteDialog = false
            this.deleteId = null
        },
        onDelete(item) {
            this.deleteId = item.id
            this.deleteDialog = true
        },
        async deleteHandler() {
            try {
                this.$loading(true)
                await trainService.deleteRailswaysRoute(this.deleteId)
                await this.getList()
            } catch (e) {
                console.error(e);
            } finally {
                this.closeDelete()
                this.$loading(false)
            }
        },
        async editRoute() {
            const routeData = {
                id: this.model.id,
                main_date: this.model.dates,
                main_station: this.model.main_station.id ?? this.model.main_station,
                is_back: this.model.is_back,
                travel_time: this.model.travel_time,
            }

            await trainService.patchRailswayRoutes(routeData)

            const postWagons = []
            const patchWagonsPromise = []
            this.wagons.forEach(item => {
                item.railway = item.railway.id ?? item.railway
                item.route = this.model.id
                if (item.id) {
                    patchWagonsPromise.push(
                        trainService.patchRouteRailways(item)
                    )
                } else {
                    postWagons.push(item)
                }
            })
            await trainService.postRouteRailways(postWagons)
            await Promise.all(patchWagonsPromise)

            this.response.route_railways.forEach(item => {
                if (!this.wagons.map(el => el.id).includes(item.id)) {
                    trainService.deleteRouteRailways(item.id)
                }
            })

            this.$refs.createMultiple.getModel()
            const stationPromise = []
            this.multipleArr.forEach(item => {
                item.station = item.station.id ?? item.station 
                item.route = this.model.id
                item.travel_time = Number(item.arrival_day)*86400 + Number(item.arrival_time)
                if (item.id) {
                    stationPromise.push(
                        trainService.patchRouteRailwayStation(item)
                    )
                } else {
                    stationPromise.push(
                        trainService.postRouteRailwayStation([item])
                    )
                }
            })
            this.response.route_stations.forEach(item => {
                if (!this.multipleArr.map(el => el.id).includes(item.id)) {
                    trainService.deleteRouteRailwayStation(item.id)
                }
            })
            await Promise.all(stationPromise)
        },
        async postRoute() {
            const dates = []
            this.model.dates.forEach(date => {
                dates.push(this.$moment(date)
                    .locale("en")
                    .valueOf())
            })
            this.$refs.createMultiple.getModel()
            const route_stations = this.multipleArr.map(el => {
                return {
                    travel_time: (el.arrival_day*86400) + (el.arrival_time),
                    station: el.station
                }
            })
            const postData = {
                dates : dates.map(date => date / 1000),
                main_station: this.model.main_station.id ?? this.model.main_station,
                is_back: this.model.is_back,
                travel_time: this.model.travel_time,
                route_stations,
                railway_routes: this.wagons,
            }
            postData.railway_routes.forEach(el => {
                el.railway = el.railway.id ?? el.railway
            })
            await trainService.postRailswaysRoute(postData)
        },
        async onSubmit() {
            if (!this.$refs.createMultiple.validate()) return
            try {
                this.$loading(true)
                if (this.model.id) {
                    await this.editRoute()
                } else {
                    await this.postRoute()
                }
                await this.getList()
            } catch(e) {
                console.error(e);
            } finally {
                this.dialog = false
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tour_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    div {
        width: 400px;
    }
}
.wagons_list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 12px;
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        color: #FF8413;
        img {
            margin-bottom: 6px;
        }
    }
}
.wagon_model {
    margin-left: -118px;
    transform: scale(.64);
}
.table_actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}
</style>