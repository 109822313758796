import { render, staticRenderFns } from "./trainsRoutes.vue?vue&type=template&id=77f56e2c&scoped=true&"
import script from "./trainsRoutes.vue?vue&type=script&lang=js&"
export * from "./trainsRoutes.vue?vue&type=script&lang=js&"
import style0 from "./trainsRoutes.vue?vue&type=style&index=0&id=77f56e2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f56e2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCheckbox,VDataTable,VForm,VIcon,VRow,VSpacer,VTabItem,VTabsItems})
